.table {
  &::-webkit-scrollbar {
    display: none;
  }

  thead {
    border-collapse: collapse;
  }

  thead th {
    padding-block: var(--chakra-space-3);
    // color: var(--chakra-colors-orange-500);
    font-size: var(--chakra-fontSizes-sm);

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  td {
    padding-block: var(--chakra-space-3);
    font-size: var(--chakra-fontSizes-sm);
  }

  tbody tr:nth-child(2n) {
    // background-color: #f5f4f3;
  }

  tbody tr:last-child {
    border: none;
  }
}
