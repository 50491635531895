.page {
  position: relative;
  background: url("~_img/trade-bg.jpg");
  background-size: contain;
  background-position: center 15vh;
  background-repeat: no-repeat;
}

.cnt {
  position: relative;
}

.line1L {
  width: 20vw;
  height: 3rem;
  border-top: 0.2rem dashed #121212;
  transform: rotate(330deg);
  transform-origin: 20vw 0;
}

.line1R {
  width: 20vw;
  height: 3rem;
  border-top: 0.2rem dashed #121212;
  transform: rotate(30deg);
  transform-origin: 0 0;
}

.line2L {
  width: 20vw;
  height: 3rem;
  border-top: 0.2rem dashed #121212;
  transform: rotate(315deg);
  transform-origin: 20vw 0;
}

.line2R {
  width: 20vw;
  height: 3rem;
  border-top: 0.2rem dashed #121212;
  transform: rotate(45deg);
  transform-origin: 0 0;
}

:global(.chakra-ui-dark) .line1L,
.line1R,
.line2L,
.line2R {
  border-color: #fff;
}

.loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--chakra-colors-blackAlpha-500);
  border-radius: var(--chakra-radii-xl);
  align-items: center;
  justify-content: center;
}
