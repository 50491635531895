.cnt {
  position: relative;
}

.colorful {
  background: linear-gradient(15deg, #0c9ff6, #776bf0 50%, #8a62ef);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.desc {
  height: 2.4em;
  font-size: 13px;
  opacity: 0.4;
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
