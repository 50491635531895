.wrap {
  position: relative;
  border: 1px solid transparent;
}

.input {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
