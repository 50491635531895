.box {
  position: relative;
  width: 20vw;
  height: 25vw;
  background: var(--chakra-colors-gray-900);
  border-radius: 0.8rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 1px dashed white;
  overflow: hidden;
}

.boxHolder {
  border: none;
  border-style: solid;
}

.empty {
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.canAdd {
  background: linear-gradient(180deg, var(--chakra-colors-blue-500), transparent);
}

.holder {
  background: linear-gradient(180deg, transparent 10%, var(--chakra-colors-blue-500), var(--chakra-colors-purple-500));
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
