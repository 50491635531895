.cnt {
  position: relative;
}

.colorful {
  background: linear-gradient(150deg, #0c9ff6, #776bf0 50%, #8a62ef);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hightMoney {
  background: linear-gradient(180deg, #fed97c, #f4b12d);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
