.page {
  position: relative;
}

.cnt {
  position: relative;
}

.entry {
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 0.25rem;
  padding: 0.5rem;
  font-size: 1.5rem;
}
