.tabBar {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  width: 100%;
}

.item {
  flex: 1;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
