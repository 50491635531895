.page {
  position: relative;
}

.cnt {
  min-height: calc(100vh - 53.33333vw);
  position: relative;
  z-index: 1;
  padding: 1.2rem 2rem;
}
