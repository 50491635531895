.page {
  position: relative;
}

.cnt {
  min-height: calc(100vh - 53.33333vw);
  position: relative;
  z-index: 1;
}

.colorful {
  background: linear-gradient(150deg, #0c9ff6, #776bf0 50%, #8a62ef);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
