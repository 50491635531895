.navbar {
  :global(.wallet-capsule-addr) {
    display: none;
  }

  @media screen and (min-width: 375px) {
    :global(.wallet-capsule-addr) {
      display: block;
    }
  }
}

.tabBar {
  :global(.tab-bar-item_icon.active) {
    color: #0c9ff6;
    background: linear-gradient(150deg, #0c9ff6, #8a62ef);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
